import React, { useEffect, useState } from 'react'
import './ContactResult.css'
import contactslist from './Contact.json';
import FlipMove from 'react-flip-move';
import ContactCard from './ContactCard';
import SearchBar from './SearchBar';



function ContactResult({ selectedContact }) {
    const contactlist = contactslist.Sheet1;
    const [selectContactlist, setContactlist] = useState([])
    const [selectedName, setSelectedName] = useState("");


    function setContactList(search) {
        if (search) {
            const lists = contactlist.filter((auto) => auto.FullText.toLowerCase().indexOf(selectedName.toLowerCase()) > -1);
            setContactlist(lists);
        }
        else {
            setContactlist(contactlist);
        }
    }


    useEffect(() => {
        if (selectedContact === "Sellers") {
            setContactList(false);
        }
    }, [selectedContact])

    useEffect(() => {
        setContactList(true);
    }, [selectedName])

    if (selectedContact === "Sellers") {
        return (
            <div className='results'>
                <SearchBar setSelectedName={setSelectedName} />
                <FlipMove id="flipmove">
                    {selectContactlist.map((contact) => (
                        <ContactCard contact={contact} />
                    ))}
                </FlipMove>
            </div>
        );
    }
    else {
        return (
            <h1>For free registration (only local Seller) contact us at sales@fishgully.co.in</h1>
        );
    }

}

export default ContactResult
