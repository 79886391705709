import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function ContactNav({ setSelectedContact }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (value === 1) {
            setSelectedContact("Contact Us");
        }
        else if (value === 0) {
            setSelectedContact("Sellers");
        }

    }, [value])

    return (
        <div className="contactnav">
            <Paper>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="inherit"
                    onChange={handleChange}                >
                    <Tab value={0} label="Sellers" />
                    <Tab value={1} label="Contact Us" />
                </Tabs>
            </Paper>
        </div>
    )
}

export default ContactNav
