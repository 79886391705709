import React, { useState, useEffect } from 'react'
import YoutubeVideoCard from './YoutubeVideoCard'
import FlipMove from 'react-flip-move';
import videolist from './Video.json';
import SearchBar from './SearchBar';

function VideoExplore() {
    const videolists = videolist.Sheet1;

    const [selectVideolist, setVideolist] = useState([])
    const [selectedName, setSelectedName] = useState("");


    function setVideoList(search) {
        if (search) {
            const lists = videolists.filter((auto) => auto.Title.toLowerCase().indexOf(selectedName.toLowerCase()) > -1);
            setVideolist(lists);
        }
        else {
            setVideolist(videolists);
        }
    }

    useEffect(() => {
        setVideoList(true);
    }, [selectedName])

    return (
        <div className='results'>
            <SearchBar setSelectedName={setSelectedName} />
            <FlipMove id="flipmove">
                {selectVideolist.map((video) => (
                    <YoutubeVideoCard video={video} />
                ))}
            </FlipMove>
        </div>
    )
}

export default VideoExplore
