import React, { forwardRef } from 'react'
import './ContactCard.css'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const Contact = forwardRef(({ contact }, ref) => {
    const classes = useStyles();

    return (
        <div ref={ref} className='videocard contactcard'>

            <Card className={classes.root} >
                <CardContent>
                    <Typography className="contactname" >
                        <b>{contact.Name}</b>
                    </Typography>
                    <Typography className="contactdetail">
                        <p><b>Phone:</b> {contact.Phone}</p>
                    </Typography>
                </CardContent>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography ><h5>Address</h5></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <p>{contact.Address}</p>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </div>
    );
})

export default Contact
