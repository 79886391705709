import React, { useState, useEffect } from 'react'
import RecipeCard from './RecipeCard'
import FlipMove from 'react-flip-move';
import recipelist from './RecipeData.json';
import SearchBar from './SearchBar';

function Explore() {
    const recipelists = recipelist.Sheet1;

    const [selectRecipelist, setRecipelist] = useState([])
    const [selectedName, setSelectedName] = useState("");


    function setRecipeList(search) {
        if (search) {
            const lists = recipelists.filter((auto) => auto.Name.toLowerCase().indexOf(selectedName.toLowerCase()) > -1);
            setRecipelist(lists);
        }
        else {
            setRecipelist(recipelists);
        }
    }

    useEffect(() => {
        setRecipeList(true);
    }, [selectedName])

    return (
        <div className='results'>
            <SearchBar setSelectedName={setSelectedName} />
            <FlipMove id="flipmove">
                {selectRecipelist.map((recipe) => (
                    <RecipeCard recipe={recipe} />
                ))}
            </FlipMove>
        </div>
    )
}

export default Explore
