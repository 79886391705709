import React from 'react'
import './PrivacyNotice.css'

function PrivacyNotice() {
    return (
        <div className="privacypolicy">
            <h1>PRIVACY NOTICE</h1>
            <br />
            <h3>Last updated September 24, 2020</h3>
            <br />
            <br />
            <section>
                <p>Thank you for choosing to be part of our community at Fish Gully ("Company", "we", "us", "our").
                We are committed to protecting your personal information and your right to privacy.
                If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information,
                please contact us at sales@fishgully.co.in.
                When you use our mobile application, as the case may be (the "App") and more generally,
                use any of our services (the "Services", which include the App), we appreciate that you are
                trusting us. We take your privacy very seriously. In this privacy notice,
                we seek to explain to you in the clearest way possible that we don’t collect
                any user information from the app. We hope you take some time to read through it carefully,
                as it is important. If there are any terms in this privacy notice that you do not agree with,
                please discontinue use of our Services immediately.
                This privacy notice applies to all information collected through our
                Services (which, as described above, includes our App), as well as, any related services,
            sales, marketing or events.</p>
                <br />
            </section>
            <section>
                <p><b>DO WE COLLECT INFORMATION FROM MINORS?</b></p>
                <p><b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.</p>
                <br />
                <p><b>DO WE MAKE UPDATES TO THIS NOTICE?</b></p>
                <p><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
                <br />
                <p><b>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b></p>
                <p>If you have questions or comments about this notice, you may email us at sales@fishgully.co.in</p>
            </section>
        </div>
    )
}

export default PrivacyNotice
