import React, { forwardRef, useState } from 'react'
import './VideoCard.css'
import Detail from './Detail'
import MoreInfo from './MoreInfo'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Card, CardContent, CardActionArea, CardMedia, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const VideoCard = forwardRef(({ fish }, ref) => {
    const classes = useStyles();

    return (
        <div ref={ref} className='videocard' onLoad="wait()">
            <Card className={classes.root}>
                <CardActionArea>
                    <CardHeader
                        title={fish.Name}
                        titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardMedia>
                        <img className="videocardimg" src={require("./" + fish.Photo)} />
                    </CardMedia>
                    <CardContent>
                        <Typography variant="display2" color="textSecondary" component="p">
                            <b>Scientific Name:</b>{fish.ScientificName}
                            <br />
                            <b>Peak Season:</b>{fish.PeakSeason}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography ><h5>Other Name</h5></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Detail fish={fish} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography ><h5>More Info</h5></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <MoreInfo fish={fish}></MoreInfo>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </div >

    );
})

export default VideoCard
