import React from 'react'
import './MoreInfo.css';

function MoreInfo({ fish }) {
    return (
        <div className="moreinfo">
            <p>{fish.MoreInfo}</p>
        </div>
    )
}

export default MoreInfo
