import React, { forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { blue } from '@material-ui/core/colors';
import ReactPlayer from 'react-player'
import YouTubeIcon from '@material-ui/icons/YouTube'
import { CardActions } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 9,
    },
    pos: {
        marginBottom: 12,
    }
}));

const YoutubeVideoCard = forwardRef(({ video }, ref) => {
    const classes = useStyles();


    return (
        <div ref={ref} className='videocard'>
            <Card >
                <div className='player-wrapper'>
                    <ReactPlayer
                        url={video.Url}
                        className='react-player'
                        width='100%'
                        height='100%'
                        controls="true"
                        config={{
                            youtube: {
                                playerVars: { modestbranding: 1 }
                            }
                        }}
                    />
                </div>
                <CardActions disableSpacing>
                    <a href={video.Url} class="link color-red">
                        <YouTubeIcon aria-label="Goto Video" >
                        </YouTubeIcon>
                    </a>
                    <h1>{video.Title}</h1>
                </CardActions>
            </Card>
        </div>
    );
})

export default YoutubeVideoCard
