import axios from 'axios';



//Base URl
const instance = axios.create({
    method: 'get',
    baseURL: "https://www.fishwatch.gov/api",
    config: {
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
    }
})

export default instance