import React, { useState, useEffect } from 'react'
import "./Header.css"
import logo from './FishGully_Logo.png';
import HomeIcon from '@material-ui/icons/Home';
import ContactsIcon from '@material-ui/icons/Contacts';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { Tab, Tabs, AppBar, Paper, Toolbar } from '@material-ui/core';
import { Link } from "react-router-dom";



function Header({ setSelectedOption }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelectedOption("Croakers");
    };

    useEffect(() => {
        const path = window.location.pathname;
        if (value === 0 && path !== "/") {
            //window.location.replace("/");
            //console.log(window.location);
            if (path === "/Contact")
                setValue(3);

            if (path === "/Recipes")
                setValue(2);

            if (path === "/Video")
                setValue(1);

        }
    }, [value])

    return (
        <Paper square >
            <AppBar position="static" color="default">
                <Toolbar >
                    <img src={logo} />
                    <Tabs
                        variant="scrollable"
                        scrollButtons="on"
                        value={value}
                        indicatorColor="primary"
                        textColor="inherit"
                        onChange={handleChange}
                    >
                        <Tab value={0} className="headertext" component={Link} to="/" icon={<HomeIcon />} />
                        <Tab value={1} className="headertext" component={Link} to="/Video" icon={<VideoLibraryIcon />} />
                        <Tab value={2} className="headertext" component={Link} to="/Recipes" icon={<MenuBookIcon />} />
                        <Tab value={3} className="headertext" component={Link} to="/Contact" icon={<ContactsIcon />} />
                    </Tabs>
                </Toolbar>
            </AppBar>
        </Paper>
    )
}

export default Header
