import React from 'react'
import './Detail.css'


function Detail({ fish }) {
    return (
        <div className="detail">
            <p><b>Kannada:</b> {fish.Kannada}</p>
            <p><b>Gujarathi:</b> {fish.Gujarathi}</p>
            <p><b>Marathi:</b> {fish.Marathi}</p>
            <p><b>Konkani:</b> {fish.Konkani}</p>
            <p><b>Malyalam:</b> {fish.Malyalam}</p>
            <p><b>Tamil:</b> {fish.Tamil}</p>
            <p><b>Telugu:</b> {fish.Telugu}</p>
            <p><b>Oriya:</b> {fish.Oriya}</p>
            <p><b>Bengali:</b> {fish.Bengali}</p>
        </div>
    )
}

export default Detail
