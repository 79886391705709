import React from 'react'
import './Nav.css'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { AppBar } from '@material-ui/core';

function Nav({ setSelectedOption }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div >
            <Paper >
                <AppBar position="static" color="default">
                    <Tabs
                        variant="scrollable"
                        scrollButtons="on"
                        value={value}
                        indicatorColor="primary"
                        textColor="inherit"
                        onChange={handleChange}
                    >
                        <Tab value={0} label="Croakers" onClick={() => setSelectedOption("Croakers")} />
                        <Tab value={1} label="Crustaceans" active onClick={() => setSelectedOption("Crustaceans")} />
                        <Tab value={2} label="Molluscs" onClick={() => setSelectedOption("Molluscs")} />
                        <Tab value={3} label="Elsamobranchs" onClick={() => setSelectedOption("Elsamobranchs")} />
                        <Tab value={4} label="Bony Fishes" onClick={() => setSelectedOption("Bony Fishes")} />
                        <Tab value={5} label="Scombroids" onClick={() => setSelectedOption("Scombroids")} />
                        <Tab value={6} label="Perches" onClick={() => setSelectedOption("Perches")} />
                        <Tab value={7} label="Other" onClick={() => setSelectedOption("Other")} />
                        <Tab value={8} label="All" onClick={() => setSelectedOption("All")} />
                    </Tabs>
                </AppBar>
            </Paper>
        </div>
    )
}

export default Nav
