import React, { useEffect, useState, useRef } from 'react'
import './Results.css'
import VideoCard from './VideoCard'
//import axios from './axios'
//import requests from './requests'
import fishlist from './FishData.json';
import FlipMove from 'react-flip-move';
import SearchBar from './SearchBar';




function Results(selectedOption) {
    const fishlists = fishlist.Sheet1;

    //console.log(fishlists)
    const [fishsortedlist, setFishList] = useState([]);
    const [selectedName, setSelectedName] = useState("");



    function setDefaultFishList(search) {
        if (selectedOption['selectedOption'] !== "All") {
            //console.log(selectedOption);
            //console.log(fishlists[0].FullText);
            const lists = fishlists.filter((auto) => auto.Group === selectedOption['selectedOption']);
            if (search) {
                const lists1 = lists.filter((auto) => auto.FullText.toLowerCase().indexOf(selectedName.toLowerCase()) > -1);
                setFishList(lists1);
            }
            else {
                setFishList(lists);
            }

        }
        else {
            //console.log(selectedName);
            //console.log(fishlists[0].FullText);
            if (search && selectedName != "") {
                const lists1 = fishlists.filter((auto) => auto.FullText.toLowerCase().indexOf(selectedName.toLowerCase()) > -1);
                setFishList(lists1);
            }
            else {
                setFishList(fishlists);
            }
        }
    }

    useEffect(() => {
        var searchtext = document.getElementById("stxt");
        //console.log(searchtext);
        searchtext.value = "";
        setDefaultFishList(false);
    }, [selectedOption])//if you add 2nd value it will run only once.
    //console.log(fishsortedlist);

    useEffect(() => {
        setDefaultFishList(true);
    }, [selectedName])


    return (
        <div className='results'>
            <SearchBar setSelectedName={setSelectedName} />
            <FlipMove id="flipmove">
                {fishsortedlist.map((fish) => (
                    <VideoCard fish={fish} />
                ))}
            </FlipMove>
        </div>
    )
}


export default Results
