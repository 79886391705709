import React, { forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import LaunchIcon from '@material-ui/icons/Launch';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatar: {
        backgroundColor: blue[900],
    },
}));

const RecipeCard = forwardRef(({ recipe }, ref) => {
    const classes = useStyles();


    return (
        <div ref={ref} className='videocard'>
            <Card className={classes.root}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {recipe.Pref}
                        </Avatar>
                    }
                    title={recipe.Name}
                    subheader={recipe.Sub}
                />
                <CardMedia
                    className={classes.media}
                    image={require("./rimg/" + recipe.Img)} />
                <CardActions disableSpacing>
                    <a href={recipe.Link} class="link color-red">
                        <LaunchIcon aria-label="Goto Blog" >
                        </LaunchIcon>
                    </a>
                </CardActions>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography ><h5>Short Desc.</h5></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <p>{recipe.Desc}</p>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </div>
    );
})

export default RecipeCard
