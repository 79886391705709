import React, { useState } from 'react';
import './App.css';
import Header from './Header';
import Nav from './Nav';
import Results from './Results';
import ScrollArrow from './ScrollArrow';
import ContactNav from './ContactNav';
import ContactResult from './ContactResult';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import VideoExplore from './VideoExplore';
import Explore from './Explore';
import PrivacyNotice from './PrivacyNotice';



function App() {

  const [selectedOption, setSelectedOption] = useState("Croakers");
  const [selectedContact, setSelectedContact] = useState("Sellers");

  return (
    <Router className="router">
      <div className="app">
        <Header setSelectedOption={setSelectedOption} />
        <Switch>
          <Route path="/Contact">
            <ContactNav setSelectedContact={setSelectedContact} />
            <ContactResult selectedContact={selectedContact} />
          </Route>
          <Route path="/Recipes">
            <Explore />
          </Route>
          <Route path="/Video">
            <VideoExplore />
          </Route>
          <Route path="/privacynotice">
            <PrivacyNotice />
          </Route>
          <Route path="/">
            <Nav setSelectedOption={setSelectedOption} />
            <Results selectedOption={selectedOption} />
          </Route>
        </Switch>
        <ScrollArrow />
      </div>
    </Router>);

}

export default App;
