import firebase from 'firebase'


const firebaseapp = firebase.initializeApp({
    apiKey: "AIzaSyB-iwGXQQ3nsuiYKm3t1IxCtA_5ar1kVhQ",
    authDomain: "fishgully-c189c.firebaseapp.com",
    databaseURL: "https://fishgully-c189c.firebaseio.com",
    projectId: "fishgully-c189c",
    storageBucket: "fishgully-c189c.appspot.com",
    messagingSenderId: "990997559914",
    appId: "1:990997559914:web:11ae5f7ce0afe143e42813",
    measurementId: "G-7X5WB3SE4W"
})

const db = firebaseapp.firestore();

export default db;